import React, { useCallback, useState } from "react"
import { graphql, Link, PageProps, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Img from "gatsby-image"
import SEO from "../components/Seo"
import Edit from "../images/icons/fa/Edit.inline.svg"
import generalInfo from "../../content/general/contacts.json"
import routes from "@routes"
import { useHeaderActions } from "../context/HeaderStateContext"
import SlateToReact from "../components/SlateToReact"
import IconWrapper from "../components/UI/IconWrapper"
import general from "../../content/general/contacts.json"
import content from "../../content/pages/contacts.json"

interface Props extends PageProps {
  pageContext: {
    isCreatedByStatefulCreatePages: boolean
    pageData: { [x: string]: any }
  }
  data: { [x: string]: any }
}

interface FormState {
  name: string
  email: string
  company: string
  tel: string
  project: string
}

const initialFormState: FormState = {
  name: "",
  email: "",
  company: "",
  tel: "",
  project: "",
}

const ContactsPage = (props: Props) => {
  const { setInitialColor } = useHeaderActions()
  const [formState, setFormState] = useState<FormState>(initialFormState)

  const updateFormState = useCallback(
    (update: Partial<FormState>) => {
      const newState = { ...formState, ...update }
      setFormState(newState)
    },
    [formState, setFormState]
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault()
      const field = e.target.name
      const val = e.target.value
      updateFormState({ [field]: val })
    },
    [updateFormState]
  )

  setInitialColor("clear")

  // Fetch site info
  const {
    site: {
      siteMetadata: { siteUrl, siteVerification },
    },
    heroImage,
    secondImage,
  }: {
    site: { siteMetadata: { siteUrl: string; siteVerification: string } }
    heroImage: any
    secondImage: any
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          siteVerification
        }
      }
      heroImage: file(relativePath: { eq: "images/contacts-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secondImage: file(relativePath: { eq: "images/contacts-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // ====================================================================
  // Json LD

  const { address, facebook, twitter, instagram, mobile, email } = generalInfo

  const businessLD = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    alternateName: "4Sight",
    description:
      "4 Sight Designs is a UK based software development company, specialising in progressive web apps, and cloud-based b2b apis and micro-services.",
    "@id": siteUrl,
    name: address?.companyName,
    url: siteUrl,
    logo: `${siteUrl}/logo-mark-black-300x300.png`,
    image: `${siteUrl}/businessImage.webp`,
    sameAs: [facebook, twitter, instagram].filter(link => !!link),
    address: address
      ? {
          "@type": "PostalAddress",
          streetAddress: `${address.line1}`,
          addressLocality: address.city,
          addressRegion: address.postCounty,
          postalCode: address.postCode,
          addressCountry: "UK",
        }
      : undefined,
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer support",
      telephone: mobile || undefined,
      email,
    },
  }

  const webSiteLD = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: "4 Sight Designs",
    url: siteUrl,
  }

  const pageLD = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: "Home",
    description:
      "4 Sight Designs is a UK based software development company, specialising in progressive web apps, and cloud-based b2b apis and micro-services.",
  }

  const breadLD = {
    "@content": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
      },
    ],
  }
  return (
    <Layout path={props.path}>
      <SEO
        path={routes.contacts}
        title={"Contacts"}
        description={
          "4 Sight Designs is a UK based software development company, specialising in progressive web apps, and cloud-based b2b apis and micro-services."
        }
        reverse
        linkedData={[businessLD, webSiteLD, pageLD, breadLD]}
        meta={[
          {
            name: "google-site-verification",
            content: siteVerification,
          },
        ]}
      />
      <div className="page-content contacts-content">
        <Link to={routes.contacts} className="contacts-link-floating">
          <IconWrapper Icon={Edit} />
        </Link>
        {/* Hero section*/}
        <div className="hero-section">
          <div className="hero-image-container">
            <Img
              className="hero-image"
              imgStyle={{
                filter: "brightness(60%)",
              }}
              fluid={heroImage.childImageSharp.fluid}
              alt={"hero image"}
              loading="eager"
            />
            <div className="image-filter blue-filter" />
          </div>
          <h1 id="hero-statement" className="rich-text">
            {content.heroSection.heading}
          </h1>
          <div className="hero-body rich-text">
            <SlateToReact value={content.heroSection.body} />
          </div>
        </div>

        {/* contact form */}
        <div className="form-container">
          <h3 className="form-header">{content.section2.heading}</h3>
          <form
            className="contact-form"
            name="contact"
            method="POST"
            data-netlify="true"
          >
            <div className="form-section">
              <label className="form-label" htmlFor="name-input">
                Name *:
              </label>
              <input
                required
                placeholder="Name *"
                className="form-input"
                type="text"
                id="name-input"
                name="name"
                autoComplete="on"
                value={formState.name}
                onChange={onChange}
              />
            </div>
            <div className="form-section">
              <label className="form-label" htmlFor="email-input">
                Email *:
              </label>
              <input
                required
                placeholder="Email *"
                className="form-input"
                type="email"
                id="email-input"
                name="email"
                autoComplete="on"
                value={formState.email}
                onChange={onChange}
              />
            </div>
            <div className="form-section">
              <label className="form-label" htmlFor="company-input">
                Company:
              </label>
              <input
                placeholder="Company Name"
                className="form-input"
                type="text"
                id="company-input"
                name="company"
                autoComplete="on"
                value={formState.company}
                onChange={onChange}
              />
            </div>
            <div className="form-section">
              <label className="form-label" htmlFor="phone-input">
                Phone:
              </label>
              <input
                placeholder="Phone Number"
                className="form-input"
                type="tel"
                id="phone-input"
                name="tel"
                autoComplete="on"
                value={formState.tel}
                onChange={onChange}
              />
            </div>
            <div className="form-section">
              <label className="form-label" htmlFor="project-input">
                Project description:
              </label>
              <textarea
                placeholder="Project Description"
                className="form-input text-area-input"
                name="project"
                id="project-input"
                value={formState.project}
                onChange={onChange}
              ></textarea>
            </div>
            <div className="form-section send-section">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>

        {/* contacts-section */}
        <div className="contacts-section">
          <div className="contact">
            <label htmlFor="phone-link">Tel:</label>
            <a href={`tel:${general.mobile}`}>{general.mobile}</a>
          </div>
          <div className="contact">
            <label htmlFor="email-link">Email:</label>
            <a href={`mailto:${general.email}`}>{general.email}</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactsPage
