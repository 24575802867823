import React, {
  useEffect,
  useCallback,
  useReducer,
  useState,
  Reducer,
  Fragment,
} from "react"
import { graphql, useStaticQuery } from "gatsby"

// utils
import routes from "@routes"
import { useHeaderActions } from "../../context/HeaderStateContext"
import {
  usePrivacyActions,
  usePrivacyState,
} from "../../context/PrivacyContext"
import { basicReducer } from "../../reducers"

// keys
import {
  analyticsKey,
  functionalKey,
  showWarningKey,
} from "../../constants/privacy"

// Components
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import ToggleInput from "../../components/UI/inputs/ToggleInput"
import IconWrapper from "../../components/UI/IconWrapper"
import ChevronUp from "../../images/icons/fa/ChevronUp.inline.svg"
import ChevronDown from "../../images/icons/fa/ChevronDown.inline.svg"

type DropDowns = {
  necessary: boolean
  functional: boolean
  analytics: boolean
}

const initDropDowns: DropDowns = {
  necessary: false,
  functional: false,
  analytics: false,
}

export default function privacySettings(props: any) {
  // Set header
  const { setInitialColor } = useHeaderActions()
  setInitialColor("white")

  // scroll page
  useEffect(() => {
    window.scroll({ top: 0 })
  }, [])

  // privacy context
  const { analytics, functional } = usePrivacyState()
  const {
    setAnalytics,
    setFunctional,
    clearAll,
    disableWarning,
  } = usePrivacyActions()

  // handle manage link
  useEffect(() => {
    if (props.location.state?.link === "manage") {
      setFunctional(true)
      disableWarning()
    }
  }, [])

  const toggleAnalytics = useCallback(() => {
    setAnalytics(!analytics)
  }, [analytics])

  const toggleFunctional = useCallback(() => {
    setFunctional(!functional)
  }, [functional])

  const handleClearAll = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    clearAll()
    setClearedModal(true)
  }, [])

  const closeClearedModal = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    typeof window !== "undefined" && window.location.reload(false)
  }, [])

  // handle drop downs
  const [dropDowns, updateDropDowns] = useReducer<
    Reducer<DropDowns, { [x in keyof DropDowns]?: boolean }>
  >(basicReducer, initDropDowns)

  const [clearedModal, setClearedModal] = useState<boolean>(false)

  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  // ====================================================================
  // Json LD

  const pageLD = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: "Privacy Settings",
    description: "Manage how you would like us to handle your data",
  }

  const breadLD = {
    "@content": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Contacts",
        item: `${siteUrl}/contacts`,
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Privacy Settings",
      },
    ],
  }

  // =====================================================================

  return clearedModal ? (
    <Fragment>
      <div className="data-cleared-modal">
        <div className="click-off" onClick={closeClearedModal} />
        <div className="cleared-modal">
          <h2 className="title">Data Cleared</h2>
          <div className="message">
            All non-essential data has been cleared from this device.
            <br />
            <br />
            Some features of this website may no longer be available, or behave
            unexpectedly.
          </div>
          <button className="continue" onClick={closeClearedModal}>
            Continue
          </button>
        </div>
      </div>
    </Fragment>
  ) : (
    <Layout path={routes.contacts}>
      <SEO
        title="Privacy Settings"
        linkedData={[pageLD, breadLD]}
        path="privacy/settings"
      />
      <div className="privacy-settings-page">
        <h1 className="title">Privacy Settings</h1>
        <div className="intro">
          Some features of this site won't work without cookies and similar
          technologies, disabling or removing cookies may affect the performance
          of this website, and your user experience.
          <br />
          <br />
          Necessary cookies cannot be turned off, without these the website
          cannot function as intended.
          <br />
          <br />
          If you have the "do not track" header activated in your browser, our
          website will respect that, and all non-essential cookies will be
          disabled by default.
        </div>
        <div className="divider" />
        <div className="toggle-sections">
          {/* Necessary Cookies */}
          <div className="section">
            <div className="control-container">
              <div className="input-wrapper">
                <span className="check">✓</span>
              </div>
              <h2>Necessary cookies</h2>
            </div>
            <div className="body">
              These cookies are essential to the use of the website. Without
              these we cannot provide you with our services.
            </div>
            <button
              className="toggle-show-cookies"
              onClick={e => {
                e.preventDefault()
                updateDropDowns({ necessary: !dropDowns.necessary })
              }}
            >
              {dropDowns.necessary
                ? ["Hide necessary cookies ", <IconWrapper Icon={ChevronUp} />]
                : [
                    "Show necessary cookies ",
                    <IconWrapper Icon={ChevronDown} />,
                  ]}
            </button>
            {dropDowns.necessary ? (
              <table className="cookie-info">
                <th>Cookie/ storage key</th>
                <th>Use</th>
                <tr>
                  <td>
                    <ul className="cookie-list">
                      <li>{showWarningKey}</li>
                    </ul>
                  </td>
                  <td>
                    Store that a user has acknowledged our cookie warning.
                  </td>
                </tr>
                <tr>
                  <td>
                    <ul className="cookie-list">
                      <li>{analyticsKey}</li>
                      <li>{functionalKey}</li>
                    </ul>
                  </td>
                  <td>Store a user's cookie preferences.</td>
                </tr>
              </table>
            ) : null}
          </div>

          {/* Functional Cookies */}
          <div className="section">
            <div className="control-container">
              <div className="input-wrapper">
                <ToggleInput
                  label=""
                  value={functional}
                  toggle={toggleFunctional}
                />
              </div>
              <h2>Functional cookies</h2>
            </div>
            <div className="body">
              These cookies are used to remember choices you have made, and data
              that you have entered in forms, to improve performance, and allow
              some additional features.
            </div>
            <button
              className="toggle-show-cookies"
              onClick={e => {
                e.preventDefault()
                updateDropDowns({ functional: !dropDowns.functional })
              }}
            >
              {dropDowns.functional
                ? ["Hide functional cookies ", <IconWrapper Icon={ChevronUp} />]
                : [
                    "Show functional cookies ",
                    <IconWrapper Icon={ChevronDown} />,
                  ]}
            </button>
            {dropDowns.functional ? (
              <table className="cookie-info">
                <th>Cookie/ storage key</th>
                <th>Use</th>
                <tr>
                  <td></td>
                  <td>
                    Indicates whether the user would like their data to be
                    stored between sessions.
                  </td>
                </tr>
              </table>
            ) : null}
          </div>

          {/* Analytical Cookies */}
          <div className="section">
            <div className="control-container">
              <div className="input-wrapper">
                <ToggleInput
                  label=""
                  value={analytics}
                  toggle={toggleAnalytics}
                />
              </div>
              <h2>Analytical cookies</h2>
            </div>
            <div className="body">
              These cookies are used to track how our users interact with our
              online services.
              <br />
              Google analytics records information such as which pages are
              visited, how long is spent on each page, and which country the
              user is from.
            </div>
            <button
              className="toggle-show-cookies"
              onClick={e => {
                e.preventDefault()
                updateDropDowns({ analytics: !dropDowns.analytics })
              }}
            >
              {dropDowns.analytics
                ? ["Hide analytical cookies ", <IconWrapper Icon={ChevronUp} />]
                : [
                    "Show analytical cookies ",
                    <IconWrapper Icon={ChevronDown} />,
                  ]}
            </button>
            {dropDowns.analytics ? (
              <table className="cookie-info">
                <th>Cookie/ storage key</th>
                <th>Use</th>
                <tr>
                  <td>
                    <ul className="cookie-list">
                      <li>_ga</li>
                      <li>_gid</li>
                      <li>_gat_gtag_XXXXXXX</li>
                    </ul>
                  </td>
                  <td>
                    These cookies are used by Google Analytics, to analyse the
                    performance of our services, and how our users interact with
                    them.
                  </td>
                </tr>
              </table>
            ) : null}
          </div>
        </div>
        <div className="divider" />
        <div className="clear-all-section">
          <h3>Clear all non-essential cookies and locally stored data.</h3>
          <button className="clear-all" onClick={handleClearAll}>
            Clear All
          </button>
        </div>
      </div>
    </Layout>
  )
}
