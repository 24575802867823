import React from "react"
import { Link } from "gatsby"
import routes from "@routes"
import colors from "../scss/pallettes/colors.module.scss"

// Storage Keys
export const k = "4-sight-designs-privacy"
export const analyticsKey = `${k}-analytics`
export const functionalKey = `${k}-functional`
export const showWarningKey = `${k}-show-warning`

export const warningMessage = [
  "This site uses cookies and similar technologies to improve your user experience",
  <br />,
  <br />,
  "For more information please see our ",
  <Link to={routes.privacyPolicy} style={{ color: colors.strongPrimary }}>
    Privacy Policy
  </Link>,
]
