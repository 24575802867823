import React, { useEffect, useState } from "react"

import NavLink from "../../UI/buttons/NavLink"
import {
  useHeaderState,
  useHeaderActions,
} from "../../../context/HeaderStateContext"
import routes from "@routes"

interface Props {
  path?: string
}

export default function Navbar({ path }: Props) {
  const { isMenuOpen, media, isCondensed } = useHeaderState()
  const { closeMenu } = useHeaderActions()
  const [headerHeight, setHeaderHeight] = useState(0)

  function handleFadeInLink() {
    const links: NodeListOf<HTMLElement> = document.querySelectorAll(
      ".nav-button"
    )
    links.forEach((link, index) => {
      if (isMenuOpen) {
        link.style.animation = `navLinkFade 0.5s ease forwards ${
          index / 7 + 0.3
        }s`
      } else {
        link.style.animation = ""
      }
    })
  }

  function navLinkStyleHandler() {
    let baseClass = "nav-links"

    if (["mobPort", "mobLand", "tabPort"].includes(media)) {
      if (isMenuOpen) {
        return baseClass + " nav-active"
      }
    } else {
      if (isCondensed) {
        return baseClass + " condensed"
      }
    }

    return baseClass
  }

  useEffect(() => {
    //lower nav links for mobile and tablet portrait
    if (["mobPort", "tabPort"].includes(media)) {
      if (document) {
        const header = document.getElementById("site-header")
        if (header) {
          setHeaderHeight(header.offsetHeight - 1)
        }
      }
    } else {
      setHeaderHeight(0)
    }
  }, [media])

  useEffect(handleFadeInLink, [isMenuOpen])

  return (
    <nav className="header-nav-bar">
      {["mobPort", "tabPort"].includes(media) && isMenuOpen && (
        <div className="click-off" onClick={closeMenu} />
      )}
      <ul
        className={navLinkStyleHandler()}
        style={{ top: `${headerHeight}px` }}
      >
        <NavLink to={routes.home} label="Home" active={path === routes.home} />
        <NavLink
          to={routes.about}
          label="About"
          active={path === routes.about}
        />
        <NavLink
          to={routes.services}
          label="Services"
          active={path === routes.services}
        />
        <NavLink
          to={routes.contacts}
          label="Contacts"
          active={path === routes.contacts}
        />
      </ul>
    </nav>
  )
}
