import React from "react"

import LogoFull from "../../../images/branding/brand-full.inline.svg"
import { useHeaderState } from "../../../context/HeaderStateContext"

export default function Logo() {
  const { media, isCondensed } = useHeaderState()

  switch (media) {
    case "mobPort":
    case "tabPort":
    case "tabLand":
    case "desktop":
      return <LogoFull />
  }
  return null
}
