import React from "react"
import { Link } from "gatsby"
import {
  useHeaderState,
  useHeaderActions,
} from "../../../context/HeaderStateContext"

interface Props {
  to: string
  label: string
  active: boolean
}

export default function NavLink(props: Props) {
  const { to, label, active } = props
  const { elementColor } = useHeaderState()
  const { closeMenu } = useHeaderActions()

  return (
    <li className="nav-button">
      <Link onClick={closeMenu} className={`nav-link ${elementColor}`} to={to}>
        {label}
        {active && (
          <div className="indicator-wrapper">
            <div className="indicator" />
          </div>
        )}
      </Link>
    </li>
  )
}
