import React, { memo } from "react"

interface Props {
  label: string
  value: boolean
  info?: string
  toggle: (e: React.MouseEvent) => void
  disabled?: boolean
}

function ToggleInput(props: Props) {
  const { label, value, info, toggle, disabled = false } = props

  return (
    <div className="form-input-box">
      <div className="toggle-container">
        <label className="toggle-label">{label}</label>
        <div className="rail-wrapper">
          <div className="indicators">
            <span className="cross">✗</span>
            <span className="check">✓</span>
          </div>
          <button
            className={`toggle-rail ${
              value ? "toggle-rail-on" : "toggle-rail-off"
            } ${disabled ? "toggle-rail-disabled" : ""}`}
            onClick={disabled ? () => {} : toggle}
            tabIndex={0}
          >
            <div className="toggle-slider" />
          </button>
        </div>
      </div>
      {info && <small className="input-info">{info}</small>}
    </div>
  )
}

export default memo(ToggleInput)
