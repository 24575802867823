import React, { Fragment, useCallback } from "react"
import { Link } from "gatsby"
import { Node } from "slate"
import Leaf from "./Leaf"
import Element from "./Element"
import routes from "@routes"

interface Props {
  value: string
  [key: string]: any
}

const isElement = (node: Node) =>
  node instanceof Object && Array.isArray(node.children)
const isInternalLink = (node: Node) => node.type === "link-int"
const handleLineBreaks = (text: string): (string | JSX.Element)[] =>
  text
    // .split(/(?=\n)|(?<=\n)/gm)
    .split(/(\n)/gm)
    .map((node: string) => (node === "\n" ? <br /> : node))

export default function SlateToReact(props: Props) {
  const content = JSON.parse(props.value)
  const renderProps: { [key: string]: any } = { ...props }
  // Remove Slate value from props
  if (renderProps.value) {
    delete renderProps.value
  }
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(
    props => <Leaf {...props} presentation={true} />,
    []
  )

  const handleLeaf = useCallback(
    (leaf: Node, i: number) => {
      return (
        <Fragment key={i}>
          {renderLeaf({
            attributes: {},
            children: (
              <Fragment>{handleLineBreaks(leaf.text as string)}</Fragment>
            ),
            leaf,
            text: leaf.text,
            ...renderProps,
          })}
        </Fragment>
      )
    },
    [renderLeaf, props]
  )

  const handleElement = useCallback(
    (element: Node = { children: [] }, i: number): JSX.Element => {
      return (
        <Fragment key={i}>
          {renderElement({
            attributes: {},
            children: handleChildren(element.children as Node[]),
            element,
            ...renderProps,
          })}
        </Fragment>
      )
    },
    [renderElement, props]
  )

  const handleInternalLink = useCallback(
    (element: Node = { children: [] }): JSX.Element => {
      return (
        <Link
          to={routes[element.url as string] || "/"}
          className={props.className}
        >
          {handleChildren(element.children as Node[])}
        </Link>
      )
    },
    [renderElement]
  )

  const handleChildren = useCallback((children: Node[]) => {
    return (
      <Fragment>
        {children.map((child, i) => {
          if (isElement(child)) {
            if (isInternalLink(child)) {
              return handleInternalLink(child)
            }
            return handleElement(child, i)
          } else {
            return handleLeaf(child, i)
          }
        })}
      </Fragment>
    )
  }, [])

  if (Array.isArray(content)) {
    return (
      <Fragment>
        {content.map((child, i) => {
          if (isElement(child)) {
            return handleElement(child, i)
          } else {
            return handleLeaf(child, i)
          }
        })}
      </Fragment>
    )
  }
  return null
}
