import React from "react"

export default ({
  attributes,
  children,
  element,
  ...props
}: {
  element: { type: string; url: string; route: string }
  attributes: React.Attributes
  children: React.ReactNode
  [key: string]: any
}) => {
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote {...attributes} {...props}>
          {children}
        </blockquote>
      )
    case "bulleted-list":
      return (
        <ul {...attributes} {...props}>
          {children}
        </ul>
      )
    case "descriptive-list":
      return (
        <dl {...attributes} {...props}>
          {children}
        </dl>
      )
    case "descriptive-title":
      return (
        <dt
          {...attributes}
          {...props}
          style={{ fontWeight: "bold", marginLeft: "1rem" }}
        >
          {children}
        </dt>
      )
    case "descriptive-details":
      return (
        <dd {...attributes} {...props}>
          {children}
        </dd>
      )
    case "heading-one":
      return (
        <h2 {...attributes} {...props}>
          {children}
        </h2>
      )
    case "heading-two":
      return (
        <h3 {...attributes} {...props}>
          {children}
        </h3>
      )
    case "link-ext":
      return (
        <a {...attributes} {...props} href={element.url}>
          {children}
        </a>
      )
    case "link-int":
      return (
        <a {...attributes} {...props} href={element.url}>
          {children}
        </a>
      )
    case "list-item":
      return (
        <li {...attributes} {...props}>
          {children}
        </li>
      )
    case "numbered-list":
      return (
        <ol {...attributes} {...props}>
          {children}
        </ol>
      )
    default:
      return (
        <p {...attributes} {...props}>
          {children}
        </p>
      )
  }
}
