import React, { useState, useContext, useEffect } from "react"
import { Media } from "../types"
import { useMedia } from "./MediaContext"
import { useScrollHeight } from "./ScrollHeightContext"

type HeaderColor = "clear" | "white" | ""
type ElementColor = "black" | "white" | ""

interface HeaderState {
  initialColor: HeaderColor
  media: Media
  isCondensed: boolean
  isMenuOpen: boolean
  elementColor: ElementColor
}

type HeaderActions = {
  setInitialColor: (state: HeaderColor) => void
  toggleMenuOpen: () => void
  closeMenu: () => void
}

const defaultState: HeaderState = {
  initialColor: "",
  media: "mobPort",
  isCondensed: false,
  isMenuOpen: false,
  elementColor: "",
}

const defaultActions: HeaderActions = {
  setInitialColor: () => {},
  toggleMenuOpen: () => {},
  closeMenu: () => {},
}

const HeaderStateContext = React.createContext<HeaderState>(defaultState)
const HeaderActionsContext = React.createContext<HeaderActions>(defaultActions)

export function HeaderStateProvider({ children }: { children: JSX.Element }) {
  const [initialColor, setInitialColor] = useState<HeaderColor>("clear")
  const [elementColor, setElementColor] = useState<ElementColor>("black")
  const { media } = useMedia()
  const { scrollHeight } = useScrollHeight()
  const [isCondensed, setIsCondensed] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  let scrollTrigger = 50

  function handleScroll() {
    if (!isCondensed) {
      if (scrollHeight >= scrollTrigger) {
        setIsCondensed(true)
      }
    } else {
      if (scrollHeight < scrollTrigger) {
        setIsCondensed(false)
      }
    }
  }

  function handleElementColor() {
    if (elementColor === "black" || elementColor === "") {
      if (!isCondensed && initialColor === "clear") {
        setElementColor("white")
      }
    }
    if (elementColor === "white" || elementColor === "") {
      if (isCondensed || initialColor === "white") {
        setElementColor("black")
      }
    }
  }

  function toggleMenuOpen() {
    setIsMenuOpen(!isMenuOpen)
  }

  function closeMenu() {
    setIsMenuOpen(false)
  }

  useEffect(() => {
    if (["tabLand", "desktop"].includes(media)) {
      handleScroll()
    }
  }, [media, scrollHeight])

  useEffect(() => {
    if (["tabLand", "desktop"].includes(media)) {
      handleElementColor()
    }
  }, [isCondensed, initialColor])

  useEffect(() => {
    if (["tabLand", "desktop"].includes(media) && isMenuOpen) {
      toggleMenuOpen()
    }
  }, [media, isMenuOpen])

  const actions = {
    setInitialColor,
    toggleMenuOpen,
    closeMenu,
  }

  return (
    <HeaderStateContext.Provider
      value={{ initialColor, media, isCondensed, isMenuOpen, elementColor }}
    >
      <HeaderActionsContext.Provider value={actions}>
        {children}
      </HeaderActionsContext.Provider>
    </HeaderStateContext.Provider>
  )
}

export const useHeaderState = (): HeaderState => useContext(HeaderStateContext)
export const useHeaderActions = (): HeaderActions =>
  useContext(HeaderActionsContext)
