import React from "react"
import { Link } from "gatsby"

import Logo from "../../images/branding/brand-full-white.inline.svg"
import Facebook from "../../images/icons/fa/Facebook.inline.svg"
import Twitter from "../../images/icons/fa/Twitter.inline.svg"
import Instagram from "../../images/icons/fa/Instagram.inline.svg"
import SocialMediaButton from "../UI/buttons/SocialMediaButton"

import routes from "@routes"
import generalInfo from "../../../content/general/contacts.json"

export default function Footer() {
  const { instagram, facebook, twitter, mobile, email } = generalInfo

  return (
    <footer className="footer">
      <Link
        className="footer-logo"
        to={routes.home}
        aria-label="Home page link"
      >
        <Logo />
      </Link>
      <div className="social-media-links">
        {instagram && (
          <SocialMediaButton
            title="Instagram"
            Icon={Instagram}
            link={instagram}
          />
        )}
        {facebook && (
          <SocialMediaButton title="Facebook" Icon={Facebook} link={facebook} />
        )}
        {twitter && (
          <SocialMediaButton title="Twitter" Icon={Twitter} link={twitter} />
        )}
      </div>
      <div className="info-container">
        <div className="contact-section">
          {mobile && (
            <a href={`tel:+44${mobile.slice(1)}`} className="contact">
              Mobile: {mobile.slice(0, 5)} {mobile.slice(5, 8)}{" "}
              {mobile.slice(8)}
            </a>
          )}
          <br />
          {email && (
            <a href={`mailto:${email}`}>
              Email: <br />
              {email}
            </a>
          )}
        </div>
        <div className="closing-section">
          <div className="site-links">
            <Link to={routes.privacyPolicy} className="site-link">
              Privacy Policy
            </Link>
            <Link to={routes.privacySettings} className="site-link">
              Privacy Settings
            </Link>
          </div>
          <div className="closing-statement">
            For all of your software needs
          </div>
          <div className="copyright">
            © {new Date().getFullYear()} 4 Sight Designs
          </div>
        </div>
      </div>
    </footer>
  )
}
