import React from "react"
import {
  useHeaderState,
  useHeaderActions,
} from "../../../context/HeaderStateContext"

export default function NavMenu() {
  const { isMenuOpen } = useHeaderState()
  const { toggleMenuOpen } = useHeaderActions()

  return (
    <div id="nav-menu-button" onClick={toggleMenuOpen}>
      <div className={isMenuOpen ? "closeNav line1" : "line1"}></div>
      <div className={isMenuOpen ? "closeNav line2 " : "line2"}></div>
      <div className={isMenuOpen ? "closeNav line3" : "line3"}></div>
    </div>
  )
}
