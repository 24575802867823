import React from "react"
import WebFont from "webfontloader"

import { MediaProvider } from "./src/context/MediaContext"
import { ScrollHeightProvider } from "./src/context/ScrollHeightContext"
import { HeaderStateProvider } from "./src/context/HeaderStateContext"
import { PrivacyProvider } from "./src/context/PrivacyContext"

export const onInitialClientRender = () => {
  WebFont.load({
    google: {
      families: [`Montserrat`, `Julius+Sans+One&display=swap`],
      text:
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890/*-+.,\"'!£$%^&()_=[]{};:@#~`¬|<>?",
    },
  })
}

export const wrapRootElement = ({ element }) => (
  <PrivacyProvider>
    <MediaProvider>
      <ScrollHeightProvider>
        <HeaderStateProvider>{element}</HeaderStateProvider>
      </ScrollHeightProvider>
    </MediaProvider>
  </PrivacyProvider>
)
