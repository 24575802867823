import React from "react"
import IconWrapper from "../IconWrapper"

interface Props {
  Icon: React.FC
  link: string
  title: string
}

export default function SocialMediaButton(props: Props) {
  const { link, Icon, title } = props

  return (
    <div className="social-media-button">
      <a href={link} title={title}>
        <IconWrapper Icon={Icon} />
      </a>
    </div>
  )
}
