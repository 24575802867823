import React, { useEffect } from "react"
import { Link } from "gatsby"

import routes from "@routes"
import { useHeaderActions } from "../context/HeaderStateContext"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => {
  // Set header
  const { setInitialColor } = useHeaderActions()
  setInitialColor("white")

  // scroll page
  useEffect(() => {
    window.scroll({ top: 0 })
  }, [])

  return (
    <Layout>
      <SEO title="404: Not found" path="404" />
      <div className="not-found-page">
        <h1 className="title">Page Not Found</h1>
        <p className="message">
          Sorry we can't seem to find that page anywhere.
          <br />
          <br />
          Perhaps you can find what you need in one of these sections:
        </p>
        <div className="links-section">
          <Link to={routes.home} className="link">
            Home
          </Link>
          <Link to={routes.about} className="link">
            About
          </Link>
          <Link to={routes.services} className="link">
            Services
          </Link>
          <Link to={routes.contacts} className="link">
            Contacts
          </Link>
          <Link to={routes.privacyPolicy} className="link">
            Privacy Policy
          </Link>
          <Link to={routes.privacySettings} className="link">
            Privacy Settings
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
