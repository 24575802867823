export function basicReducer<T>(state: T, payload: { [x: string]: any }): T {
  if (!Array.isArray(state) && !Array.isArray(payload)) {
    return { ...state, ...payload }
  }
  console.error(
    `Error: ${
      Array.isArray(state) ? "state" : "payload"
    } must be an object, not an array.`
  )
  return { ...state }
}
