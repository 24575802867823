import React from "react"
import { Link } from "gatsby"

import { useHeaderState } from "../../../context/HeaderStateContext"

import NavMenuButton from "../../UI/buttons/NavMenu"
import Navbar from "./Navbar"
import Logo from "./Logo"
import routes from "@routes"

type HeaderState =
  | "mobPort"
  | "mobLand"
  | "tabPort"
  | "tabLand clear"
  | "tabLand white"
  | "tabLand condensed"
  | "desktop clear"
  | "desktop white"
  | "desktop condensed"

interface Props {
  path?: string
}

const Header = (props: Props) => {
  const { media, initialColor, isCondensed } = useHeaderState()

  function handleHeaderClass(): HeaderState {
    switch (media) {
      case "mobPort":
      case "mobLand":
      case "tabPort":
        return media

      case "tabLand":
      case "desktop":
        if (isCondensed) {
          return `${media} condensed` as HeaderState
        }
        return `${media} ${initialColor}` as HeaderState
    }
  }

  // =========================================================
  return (
    <header id="site-header" className={handleHeaderClass()}>
      <div id="header-logo-wrapper">
        <Link to={routes.home} aria-label="Home page link">
          <Logo />
        </Link>
      </div>
      <Navbar path={props.path} />
      <NavMenuButton />
    </header>
  )
}

export default Header
