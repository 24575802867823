import React from "react"
import SlateToReact from "../SlateToReact"

interface Props {
  heading: string
  body: string
}

function ServiceCard({ heading, body }: Props) {
  return (
    <div className="service-card">
      <h3 className="service-card-heading">{heading}</h3>
      <div className="service-card-body">
        <SlateToReact value={body} />
      </div>
    </div>
  )
}

export default ServiceCard
