import React, { useCallback } from "react"
import { Link } from "gatsby"
import routes from "@routes"

import {
  usePrivacyActions,
  usePrivacyState,
} from "../../context/PrivacyContext"
import { warningMessage } from "../../constants/privacy"

import Header from "./Header"
import Footer from "./Footer"
import "../../scss/index.scss"

interface Props {
  children: React.ReactNode
  path?: string
}

const Layout = (props: Props) => {
  const { children, path } = props
  const { showWarning } = usePrivacyState()
  const { disableWarning, acceptAll } = usePrivacyActions()

  const accept = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    acceptAll()
    disableWarning()
  }, [])

  return (
    <>
      <Header path={path} />
      <main>{children}</main>
      <Footer />
      {showWarning && (
        <div className="cookie-warning-message">
          <div className="message">{warningMessage}</div>
          <div className="actions">
            By clicking "ACCEPT", you agree to our use of cookies and similar
            technologies.
            <div className="buttons">
              <Link
                className="manage"
                to={routes.privacySettings}
                state={{ link: "manage" }}
              >
                Manage
              </Link>
              <button className="accept" onClick={accept}>
                Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Layout
