import React from "react"
import { graphql, Link, PageProps, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/Seo"
import Edit from "../../images/icons/fa/Edit.inline.svg"
import SlateToReact from "../../components/SlateToReact"
import generalInfo from "../../../content/general/contacts.json"
import routes from "@routes"
import { useHeaderActions } from "../../context/HeaderStateContext"
import IconWrapper from "../../components/UI/IconWrapper"
import content from "../../../content/pages/services.json"
import ServiceCard from "../../components/cards/ServiceCard"

interface Props extends PageProps {
  pageContext: {
    isCreatedByStatefulCreatePages: boolean
    pageData: { [x: string]: any }
  }
  data: { [x: string]: any }
}

const HomePage = (props: Props) => {
  const { setInitialColor } = useHeaderActions()
  setInitialColor("clear")

  // Fetch site info
  const {
    site: {
      siteMetadata: { siteUrl, siteVerification },
    },
    heroImage,
    secondImage,
  }: {
    site: { siteMetadata: { siteUrl: string; siteVerification: string } }
    heroImage: any
    secondImage: any
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          siteVerification
        }
      }
      heroImage: file(relativePath: { eq: "images/services-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secondImage: file(relativePath: { eq: "images/home-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // ====================================================================
  // Json LD

  const { address, facebook, twitter, instagram, mobile, email } = generalInfo

  const businessLD = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    alternateName: "4Sight",
    description:
      "4 Sight Designs is a UK based software development company, specialising in progressive web apps, and cloud-based b2b apis and micro-services.",
    "@id": siteUrl,
    name: address?.companyName,
    url: siteUrl,
    logo: `${siteUrl}/logo-mark-black-300x300.png`,
    image: `${siteUrl}/businessImage.webp`,
    sameAs: [facebook, twitter, instagram].filter(link => !!link),
    address: address
      ? {
          "@type": "PostalAddress",
          streetAddress: `${address.line1}`,
          addressLocality: address.city,
          addressRegion: address.postCounty,
          postalCode: address.postCode,
          addressCountry: "UK",
        }
      : undefined,
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer support",
      telephone: mobile || undefined,
      email,
    },
  }

  const webSiteLD = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: "4 Sight Designs",
    url: siteUrl,
  }

  const pageLD = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: "Home",
    description:
      "4 Sight Designs is a UK based software development company, specialising in progressive web apps, and cloud-based b2b apis and micro-services.",
  }

  const breadLD = {
    "@content": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
      },
    ],
  }
  return (
    <Layout path={props.path}>
      <SEO
        path={routes.services}
        title={"Services"}
        description={
          "4 Sight Designs is a UK based software development company, specialising in progressive web apps, and cloud-based b2b apis and micro-services."
        }
        reverse
        linkedData={[businessLD, webSiteLD, pageLD, breadLD]}
        meta={[
          {
            name: "google-site-verification",
            content: siteVerification,
          },
        ]}
      />
      <div className="page-content services-content">
        <Link to={routes.contacts} className="contacts-link-floating">
          <IconWrapper Icon={Edit} />
        </Link>
        {/* Hero section*/}
        <div className="hero-section">
          <div className="hero-image-container">
            <Img
              className="hero-image"
              imgStyle={{
                filter: "brightness(60%)",
              }}
              fluid={heroImage.childImageSharp.fluid}
              alt={"hero image"}
              loading="eager"
            />
            <div className="image-filter blue-filter" />
          </div>
          <h1 id="hero-statement" className="rich-text">
            {content.heroSection.heading}
          </h1>
          <div className="hero-body rich-text">
            <SlateToReact value={content.heroSection.body} />
          </div>
        </div>

        {/* section2*/}
        <div className="section-2">
          <h3>{content.section2.heading}</h3>
          <div className="section-2-statement">
            <SlateToReact value={content.section2.body} />
          </div>
          <div className="services-section">
            {content.section2.services.map(service => (
              <ServiceCard heading={service.title} body={service.body} />
            ))}
          </div>
        </div>

        {/* section3 */}
        <div className="section-3">
          <h3>{content.section3.heading}</h3>
          <div className="section-3-statement">
            <SlateToReact value={content.section3.body} />
          </div>
        </div>

        {/* Banner section */}
        <div className="banner-section">
          <div className="spacer" />
          <div className="inner-container">
            <h3 className=" rich-text">{content.banner.CTA}</h3>
            <Link to={routes.contacts} className="banner-link">
              Get in touch
            </Link>
          </div>
          <div className="spacer" />
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
