import React, {
  useState,
  useContext,
  useEffect,
  Dispatch,
  useCallback,
} from "react"

import {
  analyticsKey,
  functionalKey,
  showWarningKey,
} from "../constants/privacy"

interface PrivacyState {
  analytics: boolean
  functional: boolean
  showWarning: boolean
}

interface PrivacyActions {
  setAnalytics: Dispatch<boolean>
  setFunctional: Dispatch<boolean>
  disableWarning: () => void
  clearAll: () => void
  acceptAll: () => void
}

interface WindowAsAny extends Window {
  [x: string]: any
}

const { GA_TRACKING_ID } = process.env

const hasStorage = typeof localStorage !== "undefined"

const initialAnalytics =
  hasStorage && localStorage.getItem(analyticsKey) !== null
    ? localStorage.getItem(analyticsKey) === "true"
    : false

const initialFunctional =
  hasStorage && localStorage.getItem(functionalKey) !== null
    ? localStorage.getItem(functionalKey) === "true"
    : false

const initialShowWarning =
  hasStorage && localStorage.getItem(showWarningKey) !== null
    ? localStorage.getItem(showWarningKey) === "true"
    : true

const initialPrivacyState: PrivacyState = {
  analytics: initialAnalytics,
  functional: initialFunctional,
  showWarning: initialShowWarning,
}

const initialPrivacyActions: PrivacyActions = {
  setAnalytics: () => {},
  setFunctional: () => {},
  disableWarning: () => {},
  clearAll: () => {},
  acceptAll: () => {},
}

const PrivacyStateContext = React.createContext<PrivacyState>(
  initialPrivacyState
)
const PrivacyActionsContext = React.createContext<PrivacyActions>(
  initialPrivacyActions
)

export function PrivacyProvider({ children }: { children: JSX.Element }) {
  // Handle Analytics
  const [analytics, setAnalytics] = useState(initialAnalytics)
  // persist data in localStorage on change
  useEffect(() => {
    localStorage.setItem(analyticsKey, JSON.stringify(analytics))
    ;(window as WindowAsAny)[`ga-disable-${GA_TRACKING_ID}`] = !analytics
  }, [analytics])

  // Handle Functional
  const [functional, setFunctional] = useState(initialFunctional)
  // persist data in localStorage on change
  useEffect(() => {
    localStorage.setItem(functionalKey, JSON.stringify(functional))
  }, [functional])

  // Handle Show Warning
  const [showWarning, setShowWarning] = useState(initialShowWarning)
  // persist data in localStorage on change
  useEffect(() => {
    localStorage.setItem(showWarningKey, JSON.stringify(showWarning))
  }, [showWarning])
  const disableWarning = useCallback(() => {
    setShowWarning(false)
  }, [])

  const acceptAll = useCallback(() => {
    setAnalytics(true)
    setFunctional(true)
  }, [])

  const clearAll = useCallback(() => {
    // clear context
    setAnalytics(false)
    setFunctional(false)
    setShowWarning(true)

    // clear local storage
    typeof localStorage !== "undefined" && localStorage.clear()

    // clear session storage
    typeof sessionStorage !== "undefined" && sessionStorage.clear()

    // clear cookies
    const cookies = document.cookie.split("; ")
    cookies.forEach(cookie => {
      const d = window.location.hostname.split(".")
      while (d.length > 0) {
        const cookieBase =
          encodeURIComponent(cookie.split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path="
        const p = location.pathname.split("/")
        document.cookie = cookieBase + "/"
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/")
          p.pop()
        }
        d.shift()
      }
    })
  }, [document.cookie])

  const state: PrivacyState = {
    analytics,
    functional,
    showWarning,
  }

  const actions: PrivacyActions = {
    setAnalytics,
    setFunctional,
    disableWarning,
    clearAll,
    acceptAll,
  }

  return (
    <PrivacyStateContext.Provider value={state}>
      <PrivacyActionsContext.Provider value={actions}>
        {children}
      </PrivacyActionsContext.Provider>
    </PrivacyStateContext.Provider>
  )
}

export const usePrivacyState = (): PrivacyState =>
  useContext(PrivacyStateContext)
export const usePrivacyActions = (): PrivacyActions =>
  useContext(PrivacyActionsContext)
