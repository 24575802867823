import React from "react"

interface Props {
  Icon: React.FC<{ style: {} }>
  className?: string
}

const style = {
  overflow: "visible",
  display: "inline-block",
  width: "1.125em",
  height: "1em",
  "vertical-align": "-0.125em",
  "font-size": "inherit",
}

export default ({ Icon, className }: Props) => (
  <Icon style={{ ...style, class: className }} />
)
