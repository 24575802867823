import React, { useState, useContext, useEffect } from "react"
import { useMedia } from "./MediaContext"

type ScrollContext = {
  scrollHeight: number
}

function getScrollHeight() {
  if (typeof window === "undefined") {
    return 0
  }

  return window.scrollY
}

const ScrollHeightContext = React.createContext<ScrollContext>({
  scrollHeight: 0,
})

export function ScrollHeightProvider({ children }: { children: JSX.Element }) {
  const [scrollHeight, setScrollHeight] = useState(0)
  const { media } = useMedia()
  const timeoutDuration = 25
  let throttleTimeout: boolean = false

  const clearThrottle = () => {
    setScrollHeight(getScrollHeight())
    throttleTimeout = false
  }

  const updateScrollHeight = () => {
    if (throttleTimeout === false) {
      setScrollHeight(getScrollHeight())
      throttleTimeout = true
      setTimeout(clearThrottle, timeoutDuration)
    }
  }

  useEffect(() => {
    if (["tabLand", "desktop"].includes(media)) {
      document.addEventListener("scroll", updateScrollHeight)
    }

    return () => document.removeEventListener("scroll", updateScrollHeight)
  }, [media])

  return (
    <ScrollHeightContext.Provider value={{ scrollHeight }}>
      {children}
    </ScrollHeightContext.Provider>
  )
}

export const useScrollHeight = (): { scrollHeight: number } =>
  useContext(ScrollHeightContext)
