import React, { useState, useContext, useEffect } from "react"

import breakpoints from "../scss/pallettes/breakpoints.scss"
import { Media } from "../types"

const { mobLand, tabPort, tabLand, desktop } = breakpoints

type MediaContext = {
  media: Media
}

function getMedia(): Media {
  if (typeof window !== "undefined") {
    if (window.matchMedia(`screen and (min-width: ${desktop})`).matches) {
      return "desktop"
    }
    if (window.matchMedia(`screen and (min-width: ${tabLand})`).matches) {
      return "tabLand"
    }
    if (
      window.matchMedia(
        `screen and (min-width: ${tabPort}) and (min-height: ${mobLand})`
      ).matches
    ) {
      return "tabPort"
    }
    if (window.matchMedia(`screen and (min-width: ${mobLand})`).matches) {
      return "mobLand"
    }
  }

  return "mobPort"
}

const MediaContext = React.createContext<MediaContext>({ media: getMedia() })

export function MediaProvider({ children }: { children: JSX.Element }) {
  const [media, setMedia] = useState(getMedia())
  const updateMediaQueries = () => setMedia(getMedia())

  useEffect(() => {
    window.addEventListener("resize", updateMediaQueries)

    return () => window.removeEventListener("resize", updateMediaQueries)
  }, [])

  return (
    <MediaContext.Provider value={{ media }}>{children}</MediaContext.Provider>
  )
}

export const useMedia = (): { media: Media } => useContext(MediaContext)
