import React, { Fragment } from "react"
import { Node } from "slate"

export default ({
  attributes,
  children,
  leaf,
  presentation = false,
}: {
  leaf: Node
  attributes?: React.Attributes
  children: React.ReactNode
  presentation: boolean
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = (
      <code style={{ backgroundColor: "#ccc", padding: "0 0.5rem" }}>
        {children}
      </code>
    )
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.del) {
    children = <del>{children}</del>
  }

  if (leaf.mark) {
    children = (
      <mark style={{ backgroundColor: leaf.backgroundColor as string }}>
        {children}
      </mark>
    )
  }

  if (leaf.highlight) {
    children = <span style={{ color: leaf.color as string }}>{children}</span>
  }

  if (presentation) {
    return <Fragment {...attributes}>{children}</Fragment>
  }
  return <span {...attributes}>{children}</span>
}
