import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"

import { useHeaderActions } from "../context/HeaderStateContext"

import SlateToReact from "../components/SlateToReact"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

import routes from "@routes"
import content from "../../content/pages/privacy-policy.json"

// =================================================================

type Section = {
  heading: string
  body: string
  subSections?: Section[]
}

interface Props extends PageProps {
  pageContext: {
    isCreatedByStatefulCreatePages: boolean
    pageData: {
      sections: Section[]
      metaTitle: string
      metaDescription: string
    }
  }
}

// =================================================================

export default function PrivacyPolicyTemplate({ path }: Props) {
  const { setInitialColor } = useHeaderActions()
  setInitialColor("white")

  const { sections, metaTitle, metaDescription } = content

  const handleSection = ({ heading, body, subSections }: Section) => {
    return (
      <div className="privacy-section">
        <h3 className="heading">{heading}</h3>
        <div className="section-body rich-text">
          <SlateToReact value={body} />
        </div>
        <div className="sub-sections">
          {subSections &&
            subSections.map(subSection => (
              <div className="sub-section">
                <h4 className="heading">{subSection.heading}</h4>
                <div className="section-body rich-text">
                  <SlateToReact value={subSection.body} />
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }

  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  // ====================================================================
  // Json LD

  const pageLD = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    name: "Privacy Policy",
    description:
      metaDescription ||
      "Information about how we gather, store, process, and share our user's data.",
  }

  const breadLD = {
    "@content": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Contacts",
        item: `${siteUrl}/contacts`,
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Privacy Policy",
      },
    ],
  }

  // =====================================================================

  return (
    <Layout path={path}>
      <SEO
        path={routes.privacyPolicy}
        title={metaTitle}
        description={metaDescription}
        linkedData={[pageLD, breadLD]}
      />
      <div className="privacy-content">
        <h1 className="title">Privacy & Cookie Policy</h1>
        <div className="sections">
          {sections.map(section => handleSection(section))}
        </div>
      </div>
    </Layout>
  )
}
